@import "vars";


/*==========  Desktop First  ==========*/

// ≥1200px
@include media-breakpoint-down(lg) {
  .header {
    &>.container {
      max-width: 100%; }
    .nav {
      font-size: 0.9rem; }
    &__logo {
      max-width: 120px;
      .slogan {
        letter-spacing: 1px; } } }
  .map {
    padding-bottom: 35%; }
  .btn {
    font-size: 1.1rem; }
  .section__header {
    font-size: 2rem; }

  .lightning {
    &:first-child {
      left: -30px; }
    &:last-child {
      right: -30px; } }

  .top-header {
    padding-bottom: 30%; } }
// ≥992px
@include media-breakpoint-down(md) {
  .header {
    &__bottom-bar {
      padding: 5px 10px 0;
      ul.nav > li a {
        padding-left: 5px;
        padding-right: 5px; } } }
  .services__item {
    width: 100%;
    padding-bottom: 40%; }
  .services__header {
    font-size: 1rem;
    text-align: center; }

  .pad {
    padding-top: 40px;
    padding-bottom: 40px; }
  .sm-w100 {
    max-width: 100%; }

  .lightning {
    display: none; }

  .works__item {
    width: 33%; }

  .footer__logo {
    display: flex;
    margin-bottom: $gutter;
    .logo {
      flex: none;
      margin-right: $gutter;
      max-width: 100px; } }

  .slider__item img {
    height: 500px; }

  footer .footer__menu ul {
    text-align: left; } }

// ≥768px
@include media-breakpoint-down(sm) {

  .header {
    &__top-bar {
      font-size: 0.7rem; }
    &__bottom-bar {
      padding-bottom: 5px; }
    &__menu {
      display: none;
      position: absolute;
      &.show {
        border-top: 1px solid $dark-line-color;
        display: block;
        left: 0px;
        right: 0px;
        top: 100%;
        background: $main-color;
        nav {
          ul {
            justify-content: space-around; } } } }
    .sandwich {
      font-size: 2rem; } }

  .imaged-text {
    .img {
      display: none; } }
  .units__item {
    width: calc(50% - 80px); }

  .top-header {
    background-position-x: left; }

  .top-header, .map {
    padding-bottom: 40%; }
  .works__item {
    width: 50%; }
  .dark {
    .btn {
      margin-top: $gutter; } }
  .contacts__data ul li {
    width: 100%; } }

// ≥576px
@include media-breakpoint-down(xs) {
  .top-header, .map {
    padding-bottom: 65%; }
  .works__item, .units__item {
    width: 100%; }
  .header__top-bar {
    flex-direction: column; }
  .header__logo {
    max-width: 95px; }
  .header > .container {
    padding-left: 0px;
    padding-right: 0px; }
  footer {
    .footer {
      &__phones, &__menu ul {
        text-align: center; } }
    .copyrigth {
      padding: 10px 0px; } }
  .header__menu.show nav ul {
    flex-direction: column;
    text-align: center;
    li {
      a {
        padding: 10px 5px; } } }
  section.dark--bg:after {
    display: none; }
  section.dark--bg:before {
    max-width: 100%; }
  .section__header {
    font-size: 1.8rem; }
  .units__item {
    img {
      max-width: 80px; } }

  .imaged__inner {
    flex-direction: column;
    align-items: center; }
  .imaged__text {
    min-height: 200px;
    margin-top: 20px; } }
/*==========  Mobile First  ==========*/

// ≤576px
@include media-breakpoint-up(sm);

// ≤768px
@include media-breakpoint-up(md) {
  .header {
    .sandwich {
      display: none; } } }

// ≤992px
@include media-breakpoint-up(lg);

// ≤1200px
@include media-breakpoint-up(xl);

