// User variables:
$default-font: "OpenSans", sans-serif;
$header-font: "exo2", sans-serif;

$text-color: #000;
$accent-color: #ff010b;
$main-color: #181f25;
$red-color: #fe0000;
$light-line-color: transparentize(#fff, .1);
$dark-line-color: transparentize($main-color, .5);

$grid-gutter-width: 20px;
$gutter: $grid-gutter-width;

$grid-columns: 12;
$grid-breakpoints: (xs: 0, sm: 576px, md: 992px, lg: 1200px, xl: 1440px);
$container-max-widths: (sm: 540px, md: 920px, lg: 1140px, xl: 1400px);
