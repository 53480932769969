@import "vars";
@import "fonts";
@import "libs";

::placeholder {
  color: lighten($text-color, 30%); }

::selection {
  background-color: $accent-color;
  color: $text-color; }

input, textarea {
  outline: none;
  &:focus:required:invalid {
    border-color: red; }
  &:required:valid {
    border-color: green; } }

body {
  font-size: 16px;
  min-width: 320px;
  position: relative;
  line-height: 1.65;
  font-family: $default-font;
  overflow-x: hidden;
  color: $text-color; }

a {
  color: $text-color;
  &:hover {
    color: $main-color;
    text-decoration: none; } }

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: $header-font;
  text-transform: uppercase; }

@import "header";
@import "all";
@import "footer";
@import "media";
