footer {
  background: $main-color;
  .footer {
    padding: $gutter*2 10px;
    color: $light-line-color;
    a {
      color: #fff; }
    &__logo {
      .logo {
        max-width: 140px;
        margin-bottom: 10px; } }
    &__about {
      font-size: .9rem;
      line-height: 1.2; }
    &__menu {
      ul {
        list-style: none;
        margin: 0px;
        padding: 0px;
        text-align: center;
        font-weight: 600;
        font-size: 1.1rem; } }
    &__phones {
      text-align: right; } }
  .copyright {
    background: #000;
    color: $light-line-color;
    text-align: center;
    padding: 10px; } }
