.main__wrapper {
  position: relative; }
.header {
  &__phones {
    font-size: 1.2rem;
    a {
      white-space: nowrap; } }
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  z-index: 5;
  background: #ffffff00;
  color: #fff;
  &__wrapper {
    display: flex; }
  &__logo {
    max-width: 140px;
    text-align: center;
    border-left: 1px solid $light-line-color;
    padding-left: $gutter / 2;
    padding-right: $gutter / 2;
    flex: none;
    display: flex;
    align-items: center;
    .slogan {
      color: $accent-color;
      font-family: $header-font;
      letter-spacing: .35rem; } }

  &__bars {
    display: flex;
    flex-direction: column;
    border-left: 1px solid $light-line-color;
    border-right: 1px solid $light-line-color;
    width: 100%; }
  &__top-bar {
    border-bottom: 1px solid $light-line-color;
    color: $light-line-color;
    display: flex;
    justify-content: space-between;
    padding: 5px $gutter;
    font-size: 0.9rem;
    &>div {
      display: flex;
      align-items: center; }
    a {
      color: $light-line-color;
      &:hover {
        color: #fff; } }
    i {
      font-size: 1.1rem;
      line-height: 1;
      margin-right: 4px; } }

  &__bottom-bar {
    color: #fff;
    padding: 5px $gutter 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    i {
      font-size: 1.1rem;
      line-height: 1;
      margin-right: 4px; }
    ul {
      list-style: none;
      text-transform: uppercase;
      margin: 0px;
      padding: 0px;
      &.nav {
        display: flex;
        &>li {
          a {
            padding: $gutter $gutter/2;
            display: block;
            color: #fff;
            font-weight: 600;
            &:hover, &.active {
              border-bottom: 3px solid $accent-color; } } } } }
    .languages {
      display: flex;
      align-items: center; }
    .languages__widget {
      .dropdown-lang {
        background: main-color;
        top: calc(100% + 6px);
        a {
          background: $main-color;
          color: #fff; } } } } }
