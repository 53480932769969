@import "_mixins/font-face";
@import "_mixins/font-size";

@include font-face("OpenSans", "../fonts/OpenSansBold/OpenSansBold", 'bold');
//+font-face("OpenSans", "../fonts/OpenSansBoldItalic/OpenSansBoldItalic", 'bold', 'italic')
@include font-face("OpenSans", "../fonts/OpenSansExtraBold/OpenSansExtraBold", 800);
//+font-face("OpenSans", "../fonts/OpenSansExtraBoldItalic/OpenSansExtraBoldItalic", 800, 'italic')
//+font-face("OpenSans", "../fonts/OpenSansItalic/OpenSansItalic", 'normal', 'italic')
@include font-face("OpenSans", "../fonts/OpenSansRegular/OpenSansRegular");
@include font-face("OpenSans", "../fonts/OpenSansSemiBold/OpenSansSemiBold", 600);
//+font-face("OpenSans", "../fonts/OpenSansSemiBoldItalic/OpenSansSemiBoldItalic", 600, 'italic')
@include font-face("exo2", "../fonts/Exo2Bold/Exo2Bold", bold);
@include font-face("exo2", "../fonts/Exo2SemiBold/Exo2SemiBold", 600);
