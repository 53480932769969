@function calculateRem($size) {
    $remSize: $size / $font-size;
    @return #{$remSize}rem; }

@mixin rem($size) {
    font-size: calculateRem($size); }

@mixin z-pos {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0; }
