.img-responsive {
  max-width: 100%; }

img.float-left {
  float: left; }

.text-center {
  text-align: center; }

.mart {
  margin-top: $gutter; }

.marb {
  margin-bottom: $gutter; }

.grey {
  background: #f6f6f6; }

.btn {
  border-radius: 30px;
  color: #fff;
  text-transform: uppercase;
  padding: $gutter/2 $gutter;
  background: $accent-color;
  font-weight: 600;
  font-size: 1.2rem;
  display: inline-block;
  transition: .2s;
  border: 1px solid $accent-color;
  &:focus {
    outline: none; }
  &:hover {
    color: #ffffffcc; } }

.section__header {
  font-weight: 600;
  line-height: 1.2;
  text-transform: uppercase;
  position: relative;
  font-size: 2.2rem;
  font-family: $header-font;
  padding-left: 15px;
  margin-bottom: $gutter;
  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0.5rem;
    bottom: 0.4rem;
    width: 5px;
    background: $accent-color; }
  &--blank {
    padding-left: 0px;
    &:before {
      display: none; } }
  &--sm {
    font-size: 1.8rem; }
  &--lightning {
    position: relative; } }

.pad {
  padding-top: 60px;
  padding-bottom: 60px; }

section.dark {
  background: $main-color;
  padding: $gutter * 1.5;
  &--bg {
    position: relative;
    padding: 50px;
    background: #192229;
    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0px;
      top: 0px;
      bottom: 0px;
      background: url(/storage/images/left.jpg);
      width: 400px; }
    &:after {
      content: '';
      display: block;
      position: absolute;
      right: 0px;
      top: 0px;
      bottom: 0px;
      background: url(/storage/images/right.jpg);
      width: 400px; } }
  .section__header {
    color: #fff;
    margin-bottom: 0px; } }


main, .slider__caption {
  ul {
    margin: 0px;
    padding: 0px;
    list-style: none;
    li {
      position: relative;
      display: flex;
      &:before {
        content: '';
        display: block;
        height: .5rem;
        width: .5rem;
        border-radius: 50%;
        background: $accent-color;
        margin-right: 10px;
        margin-top: 0.5rem;
        flex: none; } } } }

.map {
  position: relative;
  padding-bottom: 20%;
  border-top: 5px solid $main-color;
  transition: .2s;
  &__toggle {
    position: absolute;
    top: -10px;
    left: 0px;
    right: 0px;
    z-index: 3;
    background: $accent-color;
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
    padding: 10px 20px;
    margin-left: auto;
    margin-right: auto;
    max-width: 170px;
    text-align: center;
    cursor: pointer;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px; }

  &__show {
    display: none; }
  &.hidden {
    padding-bottom: 0px;
    .map__show {
      display: block; }
    .map__hide {
      display: none; } }
  &__wrapper {
    position: absolute;
    @include z-pos; } }


.slider {
  &__wrapper {
    color: #fff;
    position: relative;
    max-height: 30%;
    overflow: hidden; }
  &__item {
    background-size: cover;
    background-position: center;
    position: relative;
    @include z-pos;
    img {
      height: 800px;
      width: 100%;
      object-fit: cover; }
    &:before {
      content: '';
      position: absolute;
      @include z-pos;
      background: #00000055;
      z-index: 1; } }
  &__inner {
    position: absolute;
    z-index: 3;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include z-pos; }

  &__title, &__caption {
    margin-bottom: $gutter; }
  &__button {
    margin-top: $gutter*2; } }

.services {
  &__item {
    width: calc(50% - 10px);
    margin-bottom: 20px;
    padding-bottom: 25%;
    background-size: cover;
    background-position: center;
    position: relative;
    transition: .3s;
    &:hover {
      background-position-x: 0; }
    &>a {
      position: absolute;
      @include z-pos; } }
  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &.one {
      .services__item:last-child {
        width: 100%; } } }

  &__header {
    background: #fff;
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.3rem;
    padding: 10px 20px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px; } }

.works {
  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
  &__item {
    width: 25%;
    text-align: center;
    margin-bottom: 20px;
    .num {
      color: $accent-color;
      font-size: 1.1rem;
      margin-right: 5px; } }
  &__image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: $accent-color;
    position: relative;
    margin: 30px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 60px;
      height: 60px;
      object-fit: contain;
      z-index: 4; }
    &:before, &:after {
      background: transparentize($accent-color, .8);
      border-radius: 50%;
      content: '';
      position: absolute; }
    &:before {
      height: 120px;
      width: 120px;
      top: -10px;
      left: -10px; }
    &:after {
      height: 140px;
      width: 140px;
      top: -20px;
      left: -20px; } } }

.sert {
  overflow: hidden;
  &__wrapper {
    margin-top: 40px; } }

.slick-dots {
  li {
    &:before {
      display: none; } } }

.top-header {
  background-size: cover;
  background-position: center;
  position: relative;
  padding-bottom: 20%;

  &__item {
    position: absolute;
    @include z-pos;
    align-items: flex-end;
    display: flex; }

  &__inner {
    padding-bottom: 50px;
    text-align: center;
    color: #fff; }

  .breadcrumb {
    list-style: none;
    margin: 0px;
    padding: 0px;
    display: flex;
    justify-content: center;
    li {
      a {
        color: #fff; }
      &:before {
        display: none; }
      &+li {
        &:before {
          content: '/';
          display: block;
          background: #ffffff00;
          margin-top: 0px;
          margin-right: 2px;
          margin-left: 2px; } }
      &:last-child {
        &>span {
          font-weight: 600; } } } } }

.imaged-text {
  display: flex;
  .img {
    margin-right: 10px;
    width: 160px;
    flex: none; } }

.checked-list {
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    li {
      width: 45%;
      margin-bottom: $gutter;
      &:before {
        content: '\f00c';
        font: normal normal normal 14px/1 FontAwesome;
        color: #fff;
        width: 1.2rem;
        height: 1.2rem;
        display: flex;
        justify-content: center;
        align-items: center; } } } }


.units {
  &__wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }

  &__item {
    width: calc(25% - 80px);
    text-align: center;
    margin-bottom: $gutter;
    margin-left: 20px;
    margin-right: 20px; } }


.info_services__item {
  display: flex;
  margin-bottom: $gutter;
  .img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: $main-color;
    position: relative;
    margin: 30px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: none;
    img {
      width: 60px;
      height: 60px;
      object-fit: contain;
      z-index: 4; }
    &:before, &:after {
      background: transparentize($main-color, .8);
      border-radius: 50%;
      content: '';
      position: absolute; }
    &:before {
      height: 120px;
      width: 120px;
      top: -10px;
      left: -10px; }
    &:after {
      height: 140px;
      width: 140px;
      top: -20px;
      left: -20px; } }
  .text {
    margin-left: 50px; } }

.lightning {
  background: url(/storage/images/lightning.png);
  width: 40px;
  height: 200%;
  display: inline-block;
  position: absolute;
  top: -50%;
  background-repeat: no-repeat;
  &:first-child {
    left: 30px; }
  &:last-child {
    right: 30px; } }

.z-plus {
  z-index: 2;
  position: relative; }

.contacts__data {
  ul {
    display: flex;
    flex-wrap: wrap;
    li {
      width: 50%;
      flex-direction: column;
      &:before {
        display: none; } } } }

.formclass {
  padding: 0 $gutter;
  input, textarea {
    width: 100%;
    border: none;
    border-bottom: 3px solid $accent-color;
    padding: 5px; }
  .form-group {
    position: relative;
    padding-bottom: 20px;
    .help-block {
      position: absolute;
      color: $accent-color;
      font-size: 0.9rem; } } }

.modals {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 20;
    transition: .3s;
    opacity: 0;
    visibility: hidden;
    .modal__overlay {
        position: absolute;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        background: #00000055;
        z-index: 2; }
    .modal__window {
        position: relative;
        z-index: 2;
        background: #fff;
        box-shadow: 2px 2px 10px #00000055;
        transition: .5s;
        padding: 30px;
        width: 90%;
        max-width: 560px;
        .modal__close {
            position: absolute;
            font-size: 30px;
            line-height: 1;
            right: 10px;
            top: 10px;
            cursor: pointer; } }
    &.dialog {
        .modal__window {
            max-width: 300px; } }
    &.open {
        opacity: 1;
        visibility: visible; } }
.gcw {
  .gcw_title {
    background-color: $accent-color;
    color: #fff; } }

.viber, .telegram {
  background-size: cover;
  width: 16px;
  height: 16px;
  display: inline-block; }

.viber {
  background-image: url(/storage/images/viber.svg);
  color: #7b519c; }
.telegram {
  background-image: url(/storage/images/telegram.svg);
  color: #2da5d9; }

.partners__image {
  margin-left: 5px;
  margin-right: 5px; }

.imaged {
  .slick-prev:before, .slick-next:before {
    color: $accent-color;
    font-size: 40px; }
  .slick-prev, .slick-next {
    z-index: 2; }
  .slick-prev {
    left: -15px; }
  .slick-next {
    right: 5px; }
  &__item {
    margin-left: 10px;
    margin-right: 10px;
    background: #fff;
    &:focus {
      outline: none; } }
  &__header {
    font-weight: 600;
    padding: 10px 30px;
    border-bottom: 1px solid $dark-line-color;
    font-family: $header-font; }
  &__inner {
    padding: 30px;
    display: flex; }
  &__image {
    flex: none;
    margin-right: 15px; }
  &__text {
    position: relative;
    overflow: hidden;
    width: 100%;
    &.full {
      .imaged__text--inner {
        position: relative;
        padding-bottom: 30px; }
      .show-more {
        .more {
          display: none; }
        .less {
          display: inline-block;
          text-decoration: underline; } } }
    &--inner {
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px; }
    .show-more {
      position: absolute;
      bottom: 0px;
      left: 0px;
      right: 0px;
      text-align: center;
      text-decoration: underline;
      padding-top: 15px;
      background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 51%);
      cursor: pointer;
      .less {
        display: none; } } } }

.notimaged {
  &__item {
    padding: 30px;
    margin-bottom: 30px;
    border: 1px solid $dark-line-color; }
  &__name {
    font-weight: 600;
    text-transform: uppercase;
    font-family: $header-font;
    margin-bottom: 20px; }
  &__comment {
    &:before {
      content: '\f10d';
      font-family: FontAwesome;
      color: $accent-color;
      font-size: 30px;
      margin-right: 20px;
      line-height: 1; } } }
